<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#F1F4FB;"
      d="M379.586,459.034H79.448c-9.751,0-17.655-7.904-17.655-17.655V44.138
	c0-9.751,7.904-17.655,17.655-17.655h300.138c9.751,0,17.655,7.904,17.655,17.655v397.241
	C397.241,451.13,389.337,459.034,379.586,459.034z"
    />
    <path
      style="fill:#D5DCED;"
      d="M361.931,317.793C303.426,317.793,256,365.219,256,423.724c0,12.401,2.241,24.252,6.157,35.31
	h117.429c9.75,0,17.655-7.905,17.655-17.655V323.95C386.183,320.034,374.332,317.793,361.931,317.793z"
    />
    <g>
      <circle style="fill:#FFC850;" cx="141.241" cy="167.724" r="26.483" />
      <circle style="fill:#FFC850;" cx="141.241" cy="264.828" r="26.483" />
      <circle style="fill:#FFC850;" cx="141.241" cy="361.931" r="26.483" />
    </g>
    <path
      style="fill:#FFDC64;"
      d="M379.586,26.483H79.448c-9.751,0-17.655,7.904-17.655,17.655v35.31h335.448v-35.31
	C397.241,34.387,389.337,26.483,379.586,26.483z"
    />
    <path
      style="fill:#7F8499;"
      d="M344.276,291.31H203.034c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h141.241
	c4.879,0,8.828,3.953,8.828,8.828S349.155,291.31,344.276,291.31z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M308.966,256H203.034c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h105.931
	c4.879,0,8.828,3.953,8.828,8.828S313.845,256,308.966,256z"
    />
    <path
      style="fill:#7F8499;"
      d="M344.276,388.414H203.034c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h141.241
	c4.879,0,8.828,3.953,8.828,8.828S349.155,388.414,344.276,388.414z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M308.966,353.103H203.034c-4.879,0-8.828-3.953-8.828-8.828c0-4.875,3.948-8.828,8.828-8.828h105.931
	c4.879,0,8.828,3.953,8.828,8.828C317.793,349.151,313.845,353.103,308.966,353.103z"
    />
    <path
      style="fill:#7F8499;"
      d="M344.276,194.207H203.034c-4.879,0-8.828-3.953-8.828-8.828c0-4.875,3.948-8.828,8.828-8.828h141.241
	c4.879,0,8.828,3.953,8.828,8.828C353.103,190.254,349.155,194.207,344.276,194.207z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M308.966,158.897H203.034c-4.879,0-8.828-3.953-8.828-8.828c0-4.875,3.948-8.828,8.828-8.828h105.931
	c4.879,0,8.828,3.953,8.828,8.828C317.793,154.944,313.845,158.897,308.966,158.897z"
    />
    <g>
      <path
        style="fill:#7F8499;"
        d="M247.172,52.966c-4.879,0-8.828-3.953-8.828-8.828V8.828c0-4.875,3.948-8.828,8.828-8.828
		C252.052,0,256,3.953,256,8.828v35.31C256,49.013,252.052,52.966,247.172,52.966z"
      />
      <path
        style="fill:#7F8499;"
        d="M211.862,52.966c-4.879,0-8.828-3.953-8.828-8.828V8.828c0-4.875,3.948-8.828,8.828-8.828
		c4.879,0,8.828,3.953,8.828,8.828v35.31C220.69,49.013,216.742,52.966,211.862,52.966z"
      />
      <path
        style="fill:#7F8499;"
        d="M141.241,52.966c-4.879,0-8.828-3.953-8.828-8.828V8.828c0-4.875,3.948-8.828,8.828-8.828
		s8.828,3.953,8.828,8.828v35.31C150.069,49.013,146.121,52.966,141.241,52.966z"
      />
      <path
        style="fill:#7F8499;"
        d="M176.552,52.966c-4.879,0-8.828-3.953-8.828-8.828V8.828c0-4.875,3.948-8.828,8.828-8.828
		c4.879,0,8.828,3.953,8.828,8.828v35.31C185.379,49.013,181.431,52.966,176.552,52.966z"
      />
      <path
        style="fill:#7F8499;"
        d="M317.793,52.966c-4.879,0-8.828-3.953-8.828-8.828V8.828c0-4.875,3.948-8.828,8.828-8.828
		c4.879,0,8.828,3.953,8.828,8.828v35.31C326.621,49.013,322.673,52.966,317.793,52.966z"
      />
      <path
        style="fill:#7F8499;"
        d="M353.103,52.966c-4.879,0-8.828-3.953-8.828-8.828V8.828c0-4.875,3.948-8.828,8.828-8.828
		c4.879,0,8.828,3.953,8.828,8.828v35.31C361.931,49.013,357.983,52.966,353.103,52.966z"
      />
      <path
        style="fill:#7F8499;"
        d="M282.483,52.966c-4.879,0-8.828-3.953-8.828-8.828V8.828c0-4.875,3.948-8.828,8.828-8.828
		s8.828,3.953,8.828,8.828v35.31C291.31,49.013,287.362,52.966,282.483,52.966z"
      />
      <path
        style="fill:#7F8499;"
        d="M105.931,52.966c-4.879,0-8.828-3.953-8.828-8.828V8.828c0-4.875,3.948-8.828,8.828-8.828
		c4.879,0,8.828,3.953,8.828,8.828v35.31C114.759,49.013,110.81,52.966,105.931,52.966z"
      />
    </g>
    <g>
      <path
        style="fill:#707487;"
        d="M105.931,35.31c-4.879,0-8.828-3.953-8.828-8.828v17.655c0,4.875,3.948,8.828,8.828,8.828
		c4.879,0,8.828-3.953,8.828-8.828V26.483C114.759,31.358,110.81,35.31,105.931,35.31z"
      />
      <path
        style="fill:#707487;"
        d="M141.241,35.31c-4.879,0-8.828-3.953-8.828-8.828v17.655c0,4.875,3.948,8.828,8.828,8.828
		s8.828-3.953,8.828-8.828V26.483C150.069,31.358,146.121,35.31,141.241,35.31z"
      />
      <path
        style="fill:#707487;"
        d="M176.552,35.31c-4.879,0-8.828-3.953-8.828-8.828v17.655c0,4.875,3.948,8.828,8.828,8.828
		c4.879,0,8.828-3.953,8.828-8.828V26.483C185.379,31.358,181.431,35.31,176.552,35.31z"
      />
      <path
        style="fill:#707487;"
        d="M211.862,35.31c-4.879,0-8.828-3.953-8.828-8.828v17.655c0,4.875,3.948,8.828,8.828,8.828
		c4.879,0,8.828-3.953,8.828-8.828V26.483C220.69,31.358,216.742,35.31,211.862,35.31z"
      />
      <path
        style="fill:#707487;"
        d="M247.172,35.31c-4.879,0-8.828-3.953-8.828-8.828v17.655c0,4.875,3.948,8.828,8.828,8.828
		c4.879,0,8.828-3.953,8.828-8.828V26.483C256,31.358,252.052,35.31,247.172,35.31z"
      />
      <path
        style="fill:#707487;"
        d="M282.483,35.31c-4.879,0-8.828-3.953-8.828-8.828v17.655c0,4.875,3.948,8.828,8.828,8.828
		s8.828-3.953,8.828-8.828V26.483C291.31,31.358,287.362,35.31,282.483,35.31z"
      />
      <path
        style="fill:#707487;"
        d="M317.793,35.31c-4.879,0-8.828-3.953-8.828-8.828v17.655c0,4.875,3.948,8.828,8.828,8.828
		c4.879,0,8.828-3.953,8.828-8.828V26.483C326.621,31.358,322.673,35.31,317.793,35.31z"
      />
      <path
        style="fill:#707487;"
        d="M353.103,35.31c-4.879,0-8.828-3.953-8.828-8.828v17.655c0,4.875,3.948,8.828,8.828,8.828
		c4.879,0,8.828-3.953,8.828-8.828V26.483C361.931,31.358,357.983,35.31,353.103,35.31z"
      />
    </g>
    <rect
      x="61.793"
      y="79.448"
      style="fill:#D5DCED;"
      width="335.448"
      height="17.655"
    />
    <circle style="fill:#FFDC64;" cx="361.931" cy="423.724" r="88.276" />
    <path
      style="fill:#FFC850;"
      d="M406.069,494.345c-48.754,0-88.276-39.522-88.276-88.276c0-28.622,13.683-53.987,34.797-70.116
	c-44.36,4.668-78.935,42.174-78.935,87.772c0,48.754,39.522,88.276,88.276,88.276c20.131,0,38.627-6.815,53.479-18.159
	C412.339,494.164,409.225,494.345,406.069,494.345z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M361.931,455.104l-28.348,15.868c-2.86,1.601-6.283-0.886-5.644-4.102l6.332-31.863L310.42,412.95
	c-2.407-2.226-1.099-6.25,2.156-6.635l32.26-3.825l13.607-29.5c1.373-2.977,5.604-2.977,6.977,0l13.607,29.5l32.26,3.825
	c3.255,0.386,4.563,4.409,2.156,6.635l-23.851,22.057l6.332,31.863c0.639,3.215-2.784,5.703-5.644,4.102L361.931,455.104z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
